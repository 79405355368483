.nav-txt-button {
    text-decoration: none;
    display: flex;
    align-content: center;
    align-items: center;
    gap: 1rem;
    .nav-no {
        display: flex;
        justify-content: flex-end;
        width: 2.5rem;
        color: var(--b3) !important;
        &::before {
            counter-increment: my-menu-counter;
            content: "0" counter(my-menu-counter);
        }

    }
    .nav-name {
        color: #fff;
        font-size: 2.25rem;
        line-height: 2.5rem;
    }

    &.active {
        .nav-name {
        color: rgb( var(--p1));
        &::after {
            content: '/';
        }
        }
    }
}

.main-frame-header-container {
    grid-area: header;
    display: grid;
    grid-template-rows: subgrid;
    border-right: 1px solid var(--b3);
    background-color: rgb( var(--b1), 0.25);
    backdrop-filter: blur(4px);

    .main-logo {
        color: #fff;
        border-bottom: 1px solid var(--b3);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > a {
            position: relative;
            color: #fff;
            transform: rotate(0deg);
            transition: all .5s ease-in-out;

            .logo-o {
                border-radius: 50%;
                width: .5rem;
                height: .5rem;
                border: .2rem solid #fff;
                transition: all .5s ease-in-out;
            }

            .logo-i {
                position: absolute;
                top: 0rem;
                right: -1.5px;
                width: .2rem;
                height: .9rem;
                background-color: #fff;
                transform: translateX(0rem);
                transition: all .5s ease-in-out;
            }

            &:hover {
                transform: rotate(360deg);
                .logo-o {
                    border-color: rgb(var(--p1));
                }

                .logo-i {
	                animation: animate-logo-i .5s ease-in-out;
                    background-color: rgb(var(--p1));
                }
            }
        }
    }

    nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .nav-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0rem;
            padding: 0rem;
            li {
                margin-block: 5px;
                list-style-type: none;
                > a {
                    color: var(--b3);
                    font-size: 18px;
                    transition: color 0.25s ease-in-out;

                    &.active {
                        color: #fff;
                    }

                    &:hover {
                        color: rgb(var(--p1));
                    }
                }
            }
        }
    }

    .main-frame-title {
        display: none;
    }

    .main-sm-menu {
        display: none;
    }
    .main-sm-menu-container{
        display: none;
    }
    @media (max-width: 768px) {
        grid-template-columns: subgrid;
        grid-template-rows: repeat(1, minmax(0, 1fr));
        border-bottom: 1px solid var(--b3);
        z-index: 30;
        backdrop-filter: none;
        border-right: none;


        .main-frame-title {
            display: grid;
            grid-column-start: 2;
        }
        .main-logo {
            border: none;
            // border-right: 1px solid var(--b3);
             grid-column-start: 1;

        }

        .main-sm-menu {
            display: flex;
            grid-column-start: 3;
            justify-content: center;
            align-items: center;
            margin-left: -8px;

        }

        .main-sm-menu-container {
            display: block;
            height: calc(1% - 5rem);
            width: 100%;
            background-color: rgb( var(--b1), 0.5);
            backdrop-filter: blur(4px);
            position: absolute;
            top: 2.5rem;
            color: red;
            overflow: hidden;

            transition: height 0.5s ease-in-out;
            &.active-sm-menu {
                height: calc(100% - 5rem);
            }

            > ul {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 0rem;
                gap: 0.5rem;

                li {
                    width: 100%;
                    margin-block: 5px;
                    list-style-type: none;
                    > a {
                        color: var(--b3);
                        font-size: 18px;
                        transition: color 0.25s ease-in-out;
                        -webkit-tap-highlight-color: transparent;
                        -webkit-touch-callout: none;
                        -webkit-user-select: none;
                        -khtml-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        &.active {
                            color: #fff;
                            background-color: transparent;
                        }

                        &:hover {
                            color: rgb(var(--p1));
                        }
                    }
                }
            }
        }
        nav {
            display: none;
        }


    }
}

@keyframes animate-logo-i {
	0% {
        transform: translateX(0rem);
	}

	70% {
		transform: translateX(-0.8rem);
	}

	100% {
		transform: translateX(0rem);
	}
}

.custom-tooltip {
    background-color: #fff !important;
    color: #000 !important;
    font-size: 0.875rem !important;
    font-weight: 600 !important;
    padding: 0.5rem !important;
    opacity: 0 !important;
    transform: scale(.5) translateX(-50%) !important;;
    transition: opacity .5s ease-in-out, transform .25s ease-in-out !important;
    user-select: none;
  }

.animate-tooltip {
    transform: scale(1)  translateX(0%) !important;;
    opacity: 1 !important;
  }
