.main-frame-container {
    display: grid;
    grid-template-areas:
    "header top"
    "header content"
    "bottom bottom";
    grid-template-columns: 2.5rem 1fr;
    grid-template-rows: 2.5rem 1fr 2.5rem;
    position: relative;

    border: 1px solid var(--b3);
    border-radius: var(--br);
    width: 100%;
    height: 100%;
    overflow: hidden;

    transition: width 1s ease-in-out, height 1s ease-in-out;

    &.sm-main-frame {
        @media (min-width: 768px) {
            width: 90%;
            height: 90%;
        }
    }

    @media (max-width: 768px) {
        grid-template-areas:
        "header header header"
        "content content content"
        "bottom bottom bottom";
        grid-template-columns: 2.5rem 1fr 2.5rem;
    }

}








