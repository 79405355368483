.bg-circle {
    width: auto;
    position: fixed;
    transition: top .5s ease-in-out, right .5s ease-in-out, bottom .5s ease-in-out, left .5s ease-in-out, width .5s ease-in-out, height .5s ease-in-out, transform .5s ease-in-out;

    @media (min-width: 1280px) {
        height: 40%;
        min-width: 30%;
        min-height: 50%;
    }

    &.bg-tr {
        right: 0;
        top: 0;
        bottom: unset;

        @media (max-width: 1280px) {
            right: -25%;
            top: -10rem;
            width: 70%;
        }

        @media (max-width: 768px) {
            right: -25%;
            top: -10rem;
            width: 70%;
        }

        @media (max-width: 640px) {
            top: -5rem;
            width: 75%;
        }

        &.bg-center {
            top: 50% ;
            right: 50% ;
            transform: translateX(50%) translateY(-50%);
        }
    }

    &.bg-bl {
        left: 0;
        bottom: 0;
        top: unset;
        > div {
            transform: scale(0.6);
        }
        @media (max-width: 1280px) {
            left: -25%;
            bottom: -10rem;
            width: 70%;
        }

        @media (max-width: 768px) {
            left: -25%;
            bottom: -10rem;
            width: 70%;
        }

        @media (max-width: 640px) {
            bottom: -5rem;
            width: 75%;
        }

        &.bg-center {
            bottom: 50% ;
            left: 50% ;
            transform: translateX(-50%) translateY(50%);
        }
    }



    > div {
        filter: blur(180px);
        background-color: rgb(var(--p1));
        aspect-ratio: 1/1;
        height: unset;
        opacity: 0.3;
        transition: background-color 0.25s ease-in-out;

        @media (max-width: 1280px) {
            filter: blur(160px);
        }

        @media (max-width: 768px) {
            filter: blur(80px);
        }
        @media (max-width: 640px) {
            filter: blur(80px);
        }
    }

}
