.main-frame-bottom-container {
    grid-area: bottom;
    grid-template-columns: subgrid;
    border-top: 1px solid var(--b3);
    background-color: rgb( var(--b1), 0.25);
    backdrop-filter: blur(4px);
    display: grid;
    align-items: center;

    .bottom-dot {
        border-right: 1px solid var(--b3);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
            border: none;
        }
    }

    > aside {
        display: grid;
        align-items: center;
        gap: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        .local-time-container {
            display: grid;
            grid-auto-flow: column;
            justify-self: center;
            gap: .5rem;
            grid-column-start: 2;

            @media (max-width: 900px) {
                display: none !important;
            }

            .location {
                display: flex;
                align-items: center;
                gap: .5rem;
                color: var(--b3);
                font-size: 0.875rem;
                font-weight: 500;
                cursor: pointer;
                .highlight {
                    transition: all 0.5s ease-in-out;
                }
                .local-flag {
                    transition: all 0.5s ease-in-out;
                    width:  0rem;
                    height:  0.875rem;
                    opacity: 0;
                    padding-right: 0;
                }

                &:hover {
                    .local-flag {
                    width:  0.875rem;
                    opacity: 1;
                    padding-right: .5rem;
                    }
                    .highlight {
                        color: #fff;
                        font-weight: 600;
                    }
                }
            }
        }

        .social-profiles {
            display: grid;
            grid-auto-flow: column;
            justify-self: end;
            gap: .675rem;
            grid-column-start: 3;
        }

        @media (max-width: 768px) {
            grid-column: span 2 / span 2;
        }
    }

}


.blob {
	background:  rgb(var(--p1));
	border-radius: 50%;
	margin: 0;
	height: .5rem;
	width: .5rem;

	box-shadow: 0 0 0 0  rgb(var(--p1));
	transform: scale(1);
	animation: pulse 2s infinite;
    transition: background 0.25s ease-in-out, box-shadow 0.25s ease-in-out;

}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgb(var(--p1), 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}