.folder-container {
    border: 1px solid var(--b3);
    border-radius: var(--br);
    // position: relative;
    overflow: hidden;
    position: absolute;
    // opacity: 0;

    transition: top .5s ease-in-out, left .5s ease-in-out,right .5s ease-in-out;

    &.me-online {
      top: 60%;
      left: 40%;
      width: 25%;
      @media screen and (max-width: 1024px) {
        width: 60%;
        top: 70%;
        left: 20%;
      }
      @media screen and (max-width: 768px) {
        top: 73.333%;
        width: 30%;
        left: 13.33%;
      }

      @media screen and (max-width: 470px) {
        top: 83.33%;
        width: 60%;
        left: 30%;
      }
    }
    &.portrait {
      top: 38%;
      left: 70%;
      width: 25%;
      position: relative;
      .folder-body-container {
        aspect-ratio: 1/1;
        background-color: rgb(134, 210, 255);
        > img {
          width: 100%;
          height: 100%;
          transform: matrix(1.25, 0, 0, 1.25, -1, -1);
        }
      }

      @media screen and (max-width: 1024px) {
        width: 30%;
        top: 40%;
        left: 70%;
      }

      @media screen and (max-width: 768px) {
        width: 45%;
        left: 70%;
      }

      @media screen and (max-width: 470px) {
        top: 53.33%;
        .folder-body-container {
          >img {
            transform: matrix(2, 0, 0, 2, 0, 0);
          }
        }

        width: 70%;
        left: 20%;
      }
    }
    &.about-me {
      width: 58.333%;
      top: 0%;
      left: 18%;

      @media screen and (max-width: 1024px) {
        width: 80%;
        left: 10%;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        left: 0%;
      }
    }

    &.where-i-work {
      width: 33.333%;
      top: 41%;
      left: 0%;

      @media screen and (max-width: 1388px) {
      width: 60%;

        top: 50%;
        left: 0%;
      }

      @media screen and (max-width: 768px) {
        top: 56%;
        width: 50%;
        left: -5%;
      }

      @media screen and (max-width: 580px) {
        top: 68%;
      }
      @media screen and (max-width: 470px) {
        top: 76.33%;
        left: -10%;
        width: 60%;
      }
      // @media screen and (max-width: 400px) {
      //   top: 90%;
      // }
    }
  }

  .folder-title-container {
    height: 2.5rem;
    padding-inline: 16px;
    color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    background-color: rgb( var(--b1), 0.75);
    backdrop-filter: blur(4px);
    border-bottom: 1px solid var(--b3);

    .folder-controls {
      display: grid;
      grid-auto-flow: column;
      justify-self: end;
      gap: .5rem;
      grid-column-start: 3;
  }
  }

  .folder-body-container {
    color: rgb( var(--bc), 1);
    padding: 1rem;
    background-color: rgb( var(--b1), 0.45);
    backdrop-filter: blur(4px);
    font-weight: 500;
    counter-reset: line;
    p {
      font-size: 1rem;
      margin-left: 1rem;
      margin-block: 0rem;
      color: #fff;
      &::before {
        color: rgb( var(--bc), 1);
        display: inline-flex;
        justify-content: flex-end;
        margin-left: -2rem;
        padding-right: .75rem;
        text-align: right;
        width: 1.25rem;
        --tw-text-opacity: 1;
        content: counter(line);
        counter-increment: line;
        position: absolute;
      }

      a {
        display: flex;
        align-items: center;
        color: #fff;
        text-decoration: none;
        gap: 5px;
        &:hover {
          color: rgb( var(--p1), 1);
        }
      }

      span {
        &.g {
          color: rgb(143, 255, 134);
        }
        &.b {
          color: rgb(134, 210, 255);
        }
        &.b2 {
          color: rgb(64, 101, 246);
        }
        &.y {
          color: rgb(255, 212, 134);
        }
        &.p {
          color: rgb(208, 134, 255);
        }
        &.r {
          color: rgb(218, 72, 72);
        }
      }
    }
  }


