.box-custom-container {
    border: 1px solid var(--b3);
    border-radius: var(--br);
    background-color: rgb(var(--b1), .25);
    position: relative;
    overflow: hidden;
    // opacity: 0;

    transition: top .5s ease-in-out, left .5s ease-in-out,right .5s ease-in-out;

    .box-body-container {
        display: grid;
        grid-template-areas:
        "index title year"
        "empty tags button";
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto auto;

        padding-bottom: 5rem;
        padding-top: 5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        gap: 1rem;
        // column-gap: 0;
        cursor: pointer;

        &:hover {
            .box-index-container {
                opacity: 0;
            }
            .box-tags-container,
            .box-title-container {
                transform: translateX(-40px);
            }
        }

        .box-index-container {
            grid-area: index;
            font-size: 1.25rem;
            color: var(--b3);
            width: 30px;
            transition: all 0.5s ease-in-out;
        }
        .box-title-container {
            grid-area: title;
            font-size: 3rem;
            line-height: 1;
            font-weight: 500;
            text-align: left;
            transition: all 0.5s ease-in-out;
            .sm-txt {
                font-size: 1rem;
                line-height: 1.25rem;
                color: var(--b3);
            }
            .space {
                padding-right: 1rem;
            }
        }
        .box-year-container {
            grid-area: year;
            color: #fff;
            font-size: 1rem;
            line-height: 1.25rem;
            opacity: .3;
        }
        .box-empty-container {
            grid-area: empty;
        }
        .box-tags-container {
            grid-area: tags;
            display: flex;
            flex-direction: row;
            gap: 10px;
            transition: all 0.5s ease-in-out;
            flex-wrap: wrap;
            .tag-warp {
                border: 1px solid var(--b3);
                border-radius: 999999999px;
                background-color: rgb(var(--b1), .25);
                width: auto;
                padding-bottom: .5rem;
                padding-top: .5rem;
                padding-left: .75rem;
                padding-right: .75rem;
                text-transform: lowercase;
                font-size: .85rem;
                opacity: .5;
                color: rgb(255, 255, 255, .5);
            }
        }
        .box-button-container {
            grid-area: button;
            display: flex;
            justify-content: flex-end;
           .box-button {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                height: 2rem;
                min-height: 2rem;
                padding-left: .75rem;
                padding-right: .75rem;
                border: 1px solid var(--b3);
                border-radius: 9999999px;
                background-color: #000000a9;
                color: #fff;
                width: min-content;
                align-self: flex-start;
                transition: all .25s ease-in-out;
                svg {
                    transition: all .25s ease-in-out;
                    transform: rotate(180deg);
                }
                &:hover {
                    background-color: #000000d1;
                    border-color: #000000d1;
                }
                &.active {
                    svg {
                        transform: rotate(0deg);
                    }
                }
           }
        }

        @media (max-width: 800px) {
            flex-wrap: wrap;
            padding-bottom: 2rem;
            padding-top: 2rem;
            grid-template-areas:
            "index year"
            "title title"
            "tags button";
            grid-template-columns: 1fr auto;
            grid-template-rows: auto auto auto;
            row-gap: 1rem;
            column-gap: unset;
            // .box-year-container{
            //     text-align: left;
            // }
            // .box-index-container {
            //     display: none;
            // }
            .box-button-container{
                align-self: flex-end;
            }
            &:hover {
                .box-index-container {
                    opacity: 1;
                }
                .box-tags-container,
                .box-title-container {
                    transform: unset;
                }
            }
        }
    }

    .box-detail-container {
        padding-left: 2rem;
        padding-right: 2rem;
        opacity: 0;
        height: 0px;
        // overflow: hidden;
        position: relative;
        // transition: all .25s ease-in-out;
        // &.showed {
        //     opacity: 1;
        //     height: 100%;
        // }
        .box-detail {
            padding-top: 2rem;
            padding-bottom: 2rem;
            border-top: 1px solid var(--b3);

            &.company-detail {
                display: grid;
                grid-template-columns: .5fr 1.5fr;
                gap: 2rem;

                .company-logo {
                    width: 100%;
                    aspect-ratio: 1/1;
                    overflow: hidden;
                    border-radius: var(--br);
                    > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .company-work {
                    gap: 1rem;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    .company-top {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 1rem;
                        .company-top-txt {
                            display: flex;
                            align-items: center;
                            font-size: 1rem;
                            line-height: 1.25rem;
                            gap: 6px;
                            color: hsl(0, 0%, 100%);

                            a {
                                color: #fff;
                                text-decoration: none;
                                transition: color 0.25s ease-in-out;
                                &:hover {
                                    color: rgb(var(--p1));
                                }
                            }
                        }
                    }

                    .company-i-work {
text-align: left;
                    }

                }
            }
        }
    }
}