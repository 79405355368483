
.marquee-container {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .marquee-box {
    font-size: 40rem;
    font-weight: 700;
    opacity: .1;
    color: rgba($color: #000000, $alpha: 1);
    font-family: "Poppins";
    user-select: none;
    @media (max-width: 1028px) {
        font-size: 30rem;
    }
    @media (max-width: 767px) {
        font-size: 20rem;
    }
  }