.responsive-scroll {
    @keyframes responsive-scroll {
        0% {
            margin-top: 25%;
            opacity: 0;
        }
        33% {
            margin-top: 36.55%;
            opacity: 1;
        }
        100% {
            margin-top: 60%;
            opacity: 0;
        }
    }
    @keyframes responsive-scroll-rwd {
        0% {
            margin-top: 75%;
            opacity: 0;
        }
        33% {
            margin-top: 58.5%;
            opacity: 1;
        }
        100% {
            margin-top: 25%;
            opacity: 0;
        }
    }
    $rs_bg: #fafafa;
    position: relative;
    display: flex;
    justify-content: center;
    width: 2rem;
    height: 3rem;
    border: 1px solid var(--b3);
    border-radius: 10px;
    transition: all 450ms ease;
    &:before {
        content: "";
        position: absolute;
        display: block;
        width: 40%;
        height: 3px;
        max-height: 3px;
        border-bottom-left-radius: 1000px;
        border-bottom-right-radius: 1000px;
        background-color: var(--b3);
        transition: all 450ms ease;
    }
    &:after {
        content: "";
        position: absolute;
        bottom: 5px;
        display: block;
        width: 55%;
        height: 2px;
        max-height: 2px;
        border-radius: 1000px;
        background-color: var(--b3);
        opacity: 0.1;
        transition: all 950ms ease;
    }
    &:hover {
        &:after {
            opacity: 1;
        }
    }
    .scroll-thisico {
        display: block;
        width: .4rem;
        height: 2rem;

        margin-top: 65%;

        border-radius: 1000px 1000px 3px 3px;
        background-color: var(--b3);

        transform: translatex(6px) rotate(-45deg);
        transform-origin: top left;

        animation: responsive-scroll-rwd infinite 2250ms;

        transition: all 450ms ease;
    }
    &.is-notTouch {
        border: 2px solid var(--b3);
        border-radius: 25px;
        &:before,
        &:after {
            max-height: 0;
        }
        .scroll-thisico {
            height: 8px;

            margin-top: 25%;

            border-radius: 1000px;
            background-color: var(--b3);

            transform: translatex(0px) rotate(0deg);

            animation: responsive-scroll infinite 2250ms;
        }
    }
}
