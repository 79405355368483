.main-frame-top-container {
    grid-area: top;
    border-bottom: 1px solid var(--b3);
    background-color: rgb( var(--b1), 0.25);
    backdrop-filter: blur(4px);
    display: grid;
    align-items: center;
    gap: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @media (max-width: 768px) {
        display: none;
    }



    .main-frame-controls {
        display: grid;
        grid-auto-flow: column;
        justify-self: end;
        gap: .5rem;
        grid-column-start: 3;
    }


}

.main-frame-title {
    position: relative;
    display: grid;
    grid-column-start: 2;
    justify-self: center;
    align-items: center;
    color: #fff;
    grid-auto-flow: column;
    text-decoration: none;
    user-select: none;
    .sub-title {
        color: rgb(var(--p1));
        transition: color 0.25s ease-in-out;
    }

    &::after {
        content: "";
        width: 0%;
        height: 1px;
        bottom: 0;
        left: 50%;
        background:  linear-gradient(90deg,#fff 0%, rgb(var(--p1)) 100%);
        position: absolute;
        transition: color 0.25s ease-in-out ,width 0.25s ease-in-out, left 0.25s ease-in-out;
    }
    &:hover {
        .sub-title {
            color: rgb(var(--p1));
        }
        &::after {
            width: 100%;
            left: 0%;
        }
    }

}