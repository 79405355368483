

.main-frame-content-container {
    grid-area: content;
    overflow-y: scroll;
    overflow-x: hidden;

    scrollbar-color: rgb(var(--p1)) transparent !important;
    scrollbar-width: thin !important;
    color: #fff;
}

main.content-container {
    padding-bottom: 4rem;
    height: auto;

    .section-content-container {
        position: relative;
        height: 100%;

        .full-screen-section {
            overflow: hidden;
            height: calc(100dvh - 6rem);
        }

        .mouse-scroll-animation {
            position: absolute;
            left: 50%;
            bottom: 3rem;
            height: min-content;
            transform: translateX(-50%);
            opacity: .4;
        }
        .full-screen-section-container {
            overflow: hidden;
            height: calc(100dvh - 6rem);
            position: relative;
        }

        .intro-content-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.5rem;
            text-align: center;
            position: relative;
            @media (max-width: 850px) {
                gap: 1rem;
            }
            @media (max-width: 550px) {
                gap: 2rem;
            }

            @media (max-width: 450px) {
                gap: 1.5rem;
            }
            > span.title-page {
                font-size: .8rem;
                color: var(--b3);
            }

            > h1 {
                font-size: 4.5rem;
                line-height: 1;
                width: 88.333%;
                margin: 0;
                color: #fff;
                transition: font-size .5s ease-in-out;
                > span {
                    color: rgb(var(--p1));
                }
                @media (max-width: 850px) {
                    font-size: 3rem;
                }
                @media (max-width: 550px) {
                    font-size: 2rem;
                }

            }

            > p {
                color: var(--b3);
                width: 50.33%;
                @media (max-width: 1028px) {
                    width: 80.33%;
                }
            }
        }
    }
}


.main-about-container {
    width: 91.666667%;
    max-width: 1280px;
    max-height: 900px;
    height: 900px;
    position: relative;
    @media (max-width: 768px) {
        width: 83.333333%;
    }

    @media (max-width: 470px) {
        max-height: 1200px;
        height: 1200px;
    }
}

.main-title-intro-container {
    width: 66.66667%;
    margin-bottom: 2rem;

    margin: auto;

    line-height: 1;
    text-align: center;
    color: #fff;
    font-size: 4rem;

    span {
        // &:not(:last-child) {
        //     padding-right: 1rem;
        // }
        opacity: 0;
        &.space {
            padding-right: 1rem;
        }
    }
    .text-primary {
        color: rgb(var(--p1));
    }
    @media (max-width: 1028px) {
        font-size: 3rem;
        width: 100%;
    }

    @media (max-width: 600px) {
        font-size: 2.25rem;
    }
}

.normal-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    padding: 1rem;
}
.main-life-stack-container {
    padding-bottom: 9rem;
    padding-top: 9rem;
    position: relative;
    .folder-body-container {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));

        @media (max-width: 768px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        svg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            fill: #fff;
        }
    }

    .m-stack {
        padding: 1rem;
        opacity: 0.55;
    }

}

.main-experience-container {
    padding-bottom: 9rem;
    padding-top: 9rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}

.main-contact-container {
    padding-bottom: 9rem;
    padding-top: 9rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    width: 100%;
    transition: max-width .5s ease-in-out;

    .from-contact-input-container {
        padding: 1rem;
        display: flex;
        gap: 2rem;
        flex-direction: column;
        @media (max-width: 640px) {
            padding: 0rem;
        }

        .submit-button-container {
            padding-left: 1.75rem;
            align-self: end;
        }
    }
}