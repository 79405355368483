.btn-icon {
    background-color: transparent;
    padding: 0;
    height: 24px;
    line-height: 24px;
    font-size: 0.875rem;
    color: var(--b3);
    border: transparent;
    transition: color 0.25s ease-in-out, transform 0.25s ease-in-out;

    &.bth-scale {
        font-size: 1.05rem;
    }

    &.disabled {
        pointer-events:none;
    }

    &:not(&.disabled) {
        cursor: pointer;
        &:hover {
            color: rgb(var(--n));
            &.bth-scale {
                transform: scale(1.2);
            }
        }
    }



}

.btn-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .25rem;
    height: auto;
    position: relative;
    border: 1px solid var(--b3);
    border-radius: var(--br);
    background-color: #0c0c0cc8;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    padding: 1rem 1.5rem;
    text-transform: lowercase;
    transition: all 0.5s ease-in-out;
    color: #fff;
    outline: none;
    cursor: pointer;
    gap: .25rem;

    &:disabled {
        border-color:  var(--b3) !important;
        color: #fff !important;
        cursor: not-allowed;
        &::before {
            left: unset !important;
            width: 0% !important;
        }
    }

    &:before {
        border-radius: var(--br);
        content: "";
        height: 100%;
        position: absolute;
        right: 0;
        width: 0;
        top: 0;
        background-color: rgb(var(--p1), .25);
        opacity: .15;
        transition: all 0.5s ease-in-out;
    }

    &:hover {
        border-color: rgb(var(--p1));
        color: rgb(var(--p1));
        &::before {
            left: 0;
            width: 100%;
        }
    }

    .btn-label-loading,
    .btn-label {
        overflow: hidden;
        display: flex;
    }
    .bth-icon-loading,
    .btn-icon-right {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        > svg {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .bth-icon-loading {
        animation: spin 1s infinite linear;
    }
}


@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}