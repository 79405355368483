@import url(./background.scss);
@import url(./mainFrame.scss);
@import url(./button.scss);
@import url(./socialShare.scss);
@import url(./nav.scss);
@import url(./bottom.scss);
@import url(./top.scss);
@import url(./content.scss);
@import url(./fonts.scss);
@import url(./folder.scss);
@import url(./boxCustom.scss);

@import url(./mouse.scss);
@import url(./input.scss);


@font-face {
  font-family: "SpaceGrotesk";
  src: local("SpaceGrotesk"), url('../fonts/space-grotesk/SpaceGrotesk-VariableFont_wght.ttf') format("truetype");
}
.grecaptcha-badge { visibility: hidden; }
.__react_component_tooltip {
  transition: all 0.5s ease-in-out !important;
  opacity: 0 !important;
  transform: scale(0.5) !important;
  visibility: visible;
  }

  .__react_component_tooltip.show {
  visibility: visible;
  opacity: 1 !important;
  transform: scale(1) !important;
  }
:root {
  --br: 0.25rem;
  --b1: 15, 15, 15;
  --p1: 134, 210, 255;
  --b3: #444444;
  --bc: 125, 125, 125;
  --n: 255, 255, 255;
}

[data-theme="green"] {
  --b1: 15, 15, 15;
  --p1: 143, 255, 134;
}

[data-theme="blue"] {
  --b1: 15, 15, 15;
  --p1: 134, 210, 255;
}

[data-theme="yellow"] {
  --b1: 15, 15, 15;
  --p1: 255, 212, 134;
}

[data-theme="purple"] {
  --b1: 15, 15, 15;
  --p1: 208, 134, 255;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow: hidden;
  background-color: rgb( var(--b1), 1);
  margin: 0;
  padding: 0;
  font-family: "SpaceGrotesk";
  counter-reset: my-menu-counter;
  overscroll-behavior-y: none;
}

p[role="alert"]:hover::before,
p[role="alert"]:focus::before {
  display: none !important; /* Ensure the tooltip content is hidden */
}
::selection {
  color: #000;
  background: rgb( var(--p1), 1);
}

.h-dvh {
  height: 100dvh;
}

.inset-0 {
  inset: 0;
}

.absolute {
  position: absolute;
}

.rounded-full {
  border-radius: 9999999px;
}
.size-full {
  width: 100%;
  height: 100%;
}

.aspect-square {
  aspect-ratio: 1/1;
}

.border-base-300 {
  --tw-border-opacity: 1;
  border: 1px solid;
  border-color: #444444;
}

.rounded {
  border-radius: 0.25rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.relative {
  position: relative;
}

.sm-w-8-12 {
  width: 100%;
  @media (min-width: 768px) {
    width: 83.333%;
  }
  @media (min-width: 640px) {
    width: 66.6666666667%;
  }
}

.max-h-14 {
  max-height: 3.5rem;
}

.light-streak {
  &::before,
  &::after {
    background-image: linear-gradient(to top, rgb(var(--p1)), transparent);
    content: "";
    height: 9rem;
    left: 50%;
    position: absolute;
    width: 1px;
}

&::before {
  top: 0;
}
&::after {
    bottom: 0;
    transform: rotate(180deg);
  }
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

.max-w-2xl {
  max-width: 42rem;
  @media (max-width: 1024px) {
    max-width: 32rem;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
}