.social-share-container {
    border: 1px solid var(--b3);
    border-radius: var(--br);
    position: relative;
    overflow: hidden;

    &.social-share {
      width: calc(100vw - 1rem);
      max-width: 500px;
    }
  }

  .social-share-title-container {
    height: 2.5rem;
    padding-inline: 16px;
    color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    background-color: rgb( var(--b1), 0.25);
    backdrop-filter: blur(4px);
    border-bottom: 1px solid var(--b3);
  }

  .social-share-body-container {
    color: rgb( var(--bc), 1);
    padding: 80px;
    background-color: rgb( var(--b1), 0.25);
    backdrop-filter: blur(4px);
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;

    .btn-icon {
      font-size: 25px;
    }
  }