$red: #c31b1b;

.input-container {
    &.has-no {
        padding-left: 1.75rem;
    }
    position: relative;
    .input-no {
        position: absolute;
        top: 0;
        left: 0;
    }
    .from-control {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        label {
            padding-bottom: .5rem;
            color: #fff;

        }

        .input-required {
            font-size: 1rem;
            margin-left: 4px;
            color: $red;
        }
        textarea,
        input {
            flex-shrink: 1;
            font-size: 1.25rem;
            line-height: 1.75rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            height: 4rem;
            width: 100%;
            box-sizing: border-box;
            border: 1px solid var(--b3);
            border-radius: var(--br);
            background-color: #0c0c0cc8;
            color: #fff;
            outline: none;

        &:focus,
        &:focus-visible {
            border-color:  rgb(var(--p1));
        }

        @media (max-width: 640px) {
            font-size: 1rem;
            height: 3rem;
            line-height: 1.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        }

        textarea {
            resize: vertical;
            padding: 1.5rem;
            height: auto;

            @media (max-width: 640px) {
                line-height: 1.5rem;
                padding: 1rem;
            }
        }

        .input-error-message {
            padding-top: .5rem;
            margin: 0 !important;
            color: $red !important;
            &::before {
                content: "";
                display: none !important;
            }
        }
    }
}


